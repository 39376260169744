
const AvtarsData = [
    {
        key : 1,
        avtarSource : "avtar1.png", 
        avtarname : "xyz",
        avtarCode : 1
    },
    {
        key : 2,
        avtarSource : "avtar2.png", 
        avtarname : "xyz",
        avtarCode : 2
    },
    {
        key : 3,
        avtarSource : "avtar3.png", 
        avtarname : "xyz",
        avtarCode : 3
    },
    {
        key : 4,
        avtarSource : "avtar4.png", 
        avtarname : "xyz",
        avtarCode : 4
    },
    {
        key : 5,
        avtarSource : "avtar5.png", 
        avtarname : "xyz",
        avtarCode : 5
    },
    {
        key : 6,
        avtarSource : "avtar6.png", 
        avtarname : "xyz",
        avtarCode : 6
    },
    {
        key : 7,
        avtarSource : "avtar7.png", 
        avtarname : "xyz",
        avtarCode : 7
    },
    {
        key : 8,
        avtarSource : "avtar8.png", 
        avtarname : "xyz",
        avtarCode : 8
    },
    {
        key : 9,
        avtarSource : "avtar9.png", 
        avtarname : "xyz",
        avtarCode : 9
    },
    {
        key : 10,
        avtarSource : "avtar10.png", 
        avtarname : "xyz",
        avtarCode : 10
    },
    {
        key : 11,
        avtarSource : "avtar11.png", 
        avtarname : "xyz",
        avtarCode : 11
    },
    {
        key : 12,
        avtarSource : "avtar12.png", 
        avtarname : "xyz",
        avtarCode : 12
    },
    {
        key : 13,
        avtarSource : "avtar13.png", 
        avtarname : "xyz",
        avtarCode : 13
    },
    {
        key : 14,
        avtarSource : "avtar14.png", 
        avtarname : "xyz",
        avtarCode : 14
    },
    {
        key : 15,
        avtarSource : "avtar15.png", 
        avtarname : "xyz",
        avtarCode : 15
    },
    {
        key : 16,
        avtarSource : "avtar16.png", 
        avtarname : "xyz",
        avtarCode : 16
    },
    {
        key : 17,
        avtarSource : "avtar17.png", 
        avtarname : "xyz",
        avtarCode : 17
    },
    {
        key : 18,
        avtarSource : "avtar18.png", 
        avtarname : "xyz",
        avtarCode : 18
    },
    {
        key : 19,
        avtarSource : "avtar19.png", 
        avtarname : "xyz",
        avtarCode : 19
    },
    {
        key : 20,
        avtarSource : "avtar20.png", 
        avtarname : "xyz",
        avtarCode : 20
    },
    {
        key : 21,
        avtarSource : "avtar21.png", 
        avtarname : "xyz",
        avtarCode : 21
    },
    {
        key : 22,
        avtarSource : "avtar22.png", 
        avtarname : "xyz",
        avtarCode : 22
    }

]

export default AvtarsData;